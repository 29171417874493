<template>
  <Grid class="grid-nogutter">
    <Col12>
      <div class="data-table-layout">
        <Card
          id="main-content-card"
          class="layout-card full-viewport"
          :class="[CardHelperClasses.NO_SHADOWS]"
        >
          <template #header v-if="$slots?.header">
            <div ref="contentHeaderRef">
              <div class="header">
                <slot name="header" />
              </div>
            </div>
          </template>

          <template #content>
            <div class="content h-full">
              <slot name="content" />
            </div>
          </template>
        </Card>
      </div>
    </Col12>
  </Grid>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Card, CardHelperClasses } from '../../containers';
import { Col12 } from '../columns';
import { Grid } from '../grids';
import { useContentPageStore } from './../../../../../stores';
import { storeToRefs } from 'pinia';
import { useElementSize } from '@vueuse/core';

// Define options for component name
defineOptions({
  name: 'DataTableLayout',
});

// Import useElementSize from vueuse
const contentHeaderRef = ref<HTMLElement>(null);
const { height: headerHeight } = useElementSize(contentHeaderRef);

// Set content page store for heigh calculation
const contentPageStore = useContentPageStore();
const { contentPageLayoutHeight } = storeToRefs(contentPageStore);

// Ref fot parent card padding
const cardPadding = ref<string>('2rem');

// Computed for content height
const contentHeight = computed<string>(() => {
  return `calc(${contentPageLayoutHeight.value} - ${headerHeight.value}px - ${cardPadding.value})`;
});

// Compute for full viewport height
const fullViewportHeight = computed<string>(() => {
  return `calc(${contentPageLayoutHeight.value} - ${cardPadding.value})`;
});

// Context menu for rows
const cm = ref();
const onRowContextMenu = (event): void => {
  cm.value.show(event);
};

// Expose variables
defineExpose({ onRowContextMenu });
</script>

<style lang="scss" scoped>
:deep(.data-table-layout) {
  padding-left: v-bind('cardPadding');
  padding-right: v-bind('cardPadding');
  padding-top: v-bind('cardPadding');

  .layout-card {
    &.full-viewport {
      height: v-bind('fullViewportHeight');
      max-height: v-bind('fullViewportHeight');
    }

    .p-card-body {
      height: v-bind('contentHeight');
      max-height: v-bind('contentHeight');
      padding-bottom: 0;

      .p-card-content {
        height: 100%;
      }
    }
  }
}
</style>
