import { SortOrder, type PaginationConfig } from '../records';

export const marketingListPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};
