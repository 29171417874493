import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { MarketingRouteNames, MarketingRoutePathNames } from '../enums';
import { beforeEnter as listGuard } from './guards';

const List = () => import('../components/list/List.vue');

const listRoute = {
  path: buildChildURL(MarketingRoutePathNames.LIST),
  name: MarketingRouteNames.MARKETING_LIST,
  component: List,
  beforeEnter: listGuard,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [listRoute];

export default childRoutes;
